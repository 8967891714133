import { master, setLocale } from '$stores/master';
import { get } from 'svelte/store';

export const load = async ({ params }) => {
    await setLocale(params.lang);
    return {
        master: get(master),
    };
};

export const trailingSlash = 'always';
